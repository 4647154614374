import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './@shared/shared.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AuthGuard, RedirectGuard } from './auth/guard';
import { AuthGuardGuest } from './auth/guard/auth.guard-guest';
import { PusherService } from './@shared/services/pusher.service';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { GeneralConfigService } from './@shared/services/general-config.service';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    TooltipModule.forRoot()
  ],
  providers: [DatePipe,AuthGuard, AuthGuardGuest,RedirectGuard,PusherService,
    GeneralConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInit,
      multi: true,
      deps: [GeneralConfigService]
    },],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function appInit(appConfigService: GeneralConfigService) {
  let config = appConfigService.load();
  config.then((value:any) => {
    console.log(value);
    
    environment.isMobile = value;
    
  })
 
  return () => config;
}
